import macon_img from './imgs/macon.jpeg';
import macon_logo from './imgs/seal_bgd.svg';
import './App.css';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment'
import Carousel from 'react-bootstrap/Carousel'

function AppDrawRow(props){
  let appIndices =  props.indices;
  let col_style = {
    padding: "1rem",
  };
  let app_data = window.app_data;
  let cols = [];

  appIndices.forEach(
    ind => {
      if (ind < app_data.length){
        cols.push(
          <Col key={ind.toString()} xs="6" className="grid_item" style={col_style} onClick={
            (e)=>{
              window.open(
                app_data[ind].href,
                '_blank',
                'top='+0.35*window.innerHeight.toString()+',left=0,width='+window.innerWidth.toString()+',height='+0.65*window.innerHeight.toString()
              )
            }
          } >
            <Row style={{height: "80%", 'backgroundColor': "#1e2335", opacity: "0.7", color: "white", "fontSize": "7vh"}}>
              <Col className="d-flex justify-content-center align-self-center g-0">
                <i className={app_data[ind].icon}></i>
              </Col>
            </Row>
            <Row style={{height: "20%", 'backgroundColor': "#1e2335", opacity: "0.7", color: "white"}} className="appdraw_text">
              <Col className="d-flex justify-content-center align-self-center g-0" >
                {app_data[ind].name}
              </Col>
            </Row>
          </Col>
        );
      }
    }
  );

  return (
    <Row style={{
      height: props.height+"%",
    }}>
      {cols}
    </Row>
  )
}


function CarouselPageWoRef(props, ref){
  let curpage = props.page;
  let num_per_page = props.num_per_page;
  let base_index = (curpage-1)*num_per_page;
  let rows = [];
  let num_rows = Math.floor(num_per_page/2);
  let color = 'skyblue';
  if(!props.isAccessibility) color = 'gray';
  for(let i=0; i<num_rows; i++) rows.push(<AppDrawRow key={i.toString()} height={100/num_rows} indices={[base_index + 2*i, base_index + 2*i + 1]}/>);


  return (
    <Carousel.Item ref={ref} className={props.className} style={{height: "100%"}}>
      <Container className="d-block bg-white" style={{
        'width': "80%",
        'height': "100%",
        'paddingTop': "2rem",
        'paddingBottom': "5rem",
      }}>
        {rows}
      </Container>
      <Carousel.Caption className="carcaption">
        <h3 style={{fontSize: "1.3vh"}}>Page {curpage} of {props.totalpage}</h3>
      </Carousel.Caption>
      <i className="fas fa-universal-access"
         style={{
           position: 'absolute',
           bottom: "3%",
           right: "3%",
           color: color,
           "fontSize": "2vh",
           zIndex: "10",
         }}
         onClick={()=>{props.setIsAccessibility(!props.isAccessibility)}}></i>

    </Carousel.Item>
  );

}

const CarouselPage = React.forwardRef(CarouselPageWoRef);

function AppDrawer(props){
  let app_data = window.app_data;
  let num_per_page = props.num;
  let num_pages = Math.ceil(app_data.length/num_per_page);
  let pages = [];
  for(let i=0; i<num_pages; i++) pages.push(<CarouselPage isAccessibility={props.isAccessibility} setIsAccessibility={props.setIsAccessibility} key={i} page={i+1} totalpage={num_pages} num_per_page={num_per_page}/>);



  return (
    <Carousel id='myCarousel' variant="dark" controls={true} interval="200000">
      {pages}

    </Carousel>
  );

}

class Weather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weather: null,
    };

  }

  componentDidMount() {
    this.updateWeather();
    this.weatherID = setInterval(
      () => this.updateWeather(),
      60000
    );
  }

  updateWeather = () => {
    fetch("/weather").then((resp)=>{
      return resp.json();
    }).then((r) => {

      this.setState({
        'weather': r
      })

    })
  }

  render() {
    if(this.state.weather != null){
    return (
      <div style={{maxHeight: "100%"}}>
        <row style={{height: "50%", display: 'inline-block', paddingTop: 0, margin: 0}}>
          <img style={{padding: 0, margin: 0}} src={this.state.weather.current.condition.icon}/>
        </row>
        <row style={{height: "50%", fontSize: "1vh", display: 'inline-block'}} className="text-center align-self-center">
          {this.state.weather.current.condition.text}<br></br>Temperature: {this.state.weather.current.temp_f} F
        </row>
      </div>
    );
    }
    return (<div></div>);
  }
}


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: moment().format('llll'),
      isAccessibility: false
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => {
      this.setState({ date: moment().format('llll') });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  setIsAccessibility = (val) => {
    this.setState({isAccessibility: val});
  }

  updateDate = () => {
    this.setState({date: moment().format('llll')});
  }

  render() {

    let styles;
    let isAccessibility = this.state.isAccessibility;
    let setIsAccessibility = this.setIsAccessibility;
    const { date } = this.state;
    let num_items_per_page;

    if(! isAccessibility){
      styles = {
        'header_style':{
          height: "7.5%",
        },
        'sshow_style':{
          height: "19.06%",
        },
        'info_style':{
          height: "11.46%",
        },
        'appdraw_style':{
          height: "59.98%",
        },
        'footer_style':{
          height: "1.97%",
        },
      };
      num_items_per_page = 6;
    }else{
      styles = {
        'header_style':{
          height: "7.5%",
        },
        'sshow_style':{
          height: "33.07%",
        },
        'info_style':{
          height: "11.46%",
        },
        'appdraw_style':{
          height: "45.83%",
        },
        'footer_style':{
          height: "1.97%",
        },
      };
      num_items_per_page = 4;
    }

    let info_text = "Touch any Button to learn more...";
    let title = "Macon-Bibb County Kiosk Information System";
    return (
      <Container id='base_container' fluid="true" style={{maxWidth:"100vw", height:"100vh"}}>
        <Row id='header_bar' className="g-0 bg-white controw" style={styles['header_style']}>
          <Col xs={2} className="text-center align-self-center g-0 headcol">
            <div style={{position:'relative'}}>
              <img src={macon_logo} alt="logo" style={{
                "height": "8vh",
                "width": "auto",
                "position": "relative",
                "bottom": "-30px",
                "left": "30px",
                "zIndex": "1"
              }}/>
            </div>
          </Col>
          <Col xs={8} className="text-center align-self-center g-0 headcol">
            <div className="d-flex justify-content-center align-self-center">
              <div>{title}<br></br><div style={{fontSize: "1vh"}}>{date}</div></div>
            </div>

          </Col>
          <Col xs={2} className="text-center align-self-center g-0 headcol h-100">
            <Weather/>
          </Col>
        </Row>
        <Row id='sshow_bar' className="g-0 controw" style={styles['sshow_style']}>
          <video autoPlay muted loop
            style={{
              "position":"absolute",
              "width":"100vw",
              "left":"0px",
              "top":"0px"
            }}
          >
            <source src="https://amerson.maconbibb.us/wp-content/uploads/2016/08/amerson2-HD.mp4" type="video/mp4" />
          </video>
        </Row>
        <Row id='info_bar' className="g-0 controw" style={styles['info_style']}>
          <Col className="d-flex justify-content-center align-self-center g-0" style={{"margin":0, position:'relative'}}>
            {info_text}
          </Col>
        </Row>
        <Row id='appdraw_bar' className="g-0 flex-grow-1 controw" style={styles['appdraw_style']}>
          <AppDrawer num={num_items_per_page  } isAccessibility={isAccessibility} setIsAccessibility={setIsAccessibility} />
        </Row>
        <Row id='footer_bar' className="g-0 controw" style={styles['footer_style']}>
        </Row>
      </Container>
    );
  }
}


export default App;
